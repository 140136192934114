import {Category, Place, Section, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {SeatingReservationsSummary} from '@wix/ambassador-seating-v1-seating-reservation/types'
import {
  GetFormattedMoney,
  PlaceWithTicketInfo,
  getPreliminaryInvoice,
  getPricingOption,
  getPricingOptionLabel,
  getPricingOptions,
  getPricingOptionsRange,
  getTaxConfig,
  getTicketDefinitionCurrency,
  hasPricingOptions,
  isDonationTicketDefinition,
  isPricingOptionsWithSamePrice,
  saleEnded,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import type {GetTicketPriceText, PriceFormatters} from '../../../../../commons/hooks/currency'
import {State} from '../../types'
import {getPlacesInBasket} from './places'
import {getTicketDefinitionByExternalId} from './seating'

export const isPlaceReservableAsWhole = (place: PlaceWithTicketInfo) => place?.reservationOptions?.reserveWholeElement

export const isPlaceInBasket = (place: PlaceWithTicketInfo) => place.quantity > 0

export const isPlaceArea = (place: Place) => place.elementType === Type.AREA

export const getElementLabelTitle = (elementType: Type, t: TFunction) => {
  const elementLabelHeaderMap = {
    [Type.AREA]: t('seatings_area'),
    [Type.ROW]: t('seatings_row'),
    [Type.TABLE]: t('seatings_table'),
    [Type.ROUND_TABLE]: t('seatings_table'),
  }

  return elementLabelHeaderMap[elementType]
}

export const getPlaceInfoText = ({section, elementLabel, elementType, label}: PlaceWithTicketInfo, t: TFunction) => {
  const sectionLabel = section ? `${t('seatings_sector')} ${section}` : null
  const elLabel = elementLabel ? `${getElementLabelTitle(elementType, t)} ${elementLabel}` : null
  const placeLabel = label ? `${t('seatings_seat')} ${label}` : null
  return `${[sectionLabel, elLabel, placeLabel].filter(Boolean).join(' ')}`
}

export const getPlaceTicketPriceText = ({
  t,
  ticket,
  priceOverride,
  getTicketPriceText,
  getFormattedMoney,
}: {
  ticket: wix.events.ticketing.Ticket
  t: TFunction
  priceOverride?: string
} & PriceFormatters) => {
  if (priceOverride) {
    return getTicketPriceText({amount: priceOverride, currency: getTicketDefinitionCurrency(ticket)})
  } else if (isDonationTicketDefinition(ticket)) {
    return t('tickets.donation')
  } else if (hasPricingOptions(ticket)) {
    if (isPricingOptionsWithSamePrice(ticket)) {
      return getTicketPriceText(getPricingOptions(ticket)[0].price)
    } else {
      return getPricingOptionsRangeLabel(ticket, t, getFormattedMoney)
    }
  }
  return getTicketPriceText(ticket.price)
}

export const getPricingOptionsRangeLabel = (
  ticket: wix.events.ticketing.Ticket,
  t: TFunction,
  getFormattedMoney: GetFormattedMoney,
) => {
  const priceRange = getPricingOptionsRange(ticket)
  return t('tickets.pricingOption.range', {
    min: getFormattedMoney(priceRange.min),
    max: getFormattedMoney(priceRange.max),
  })
}

export const getActivePricingOptionLabel = (place: PlaceWithTicketInfo, getFormattedMoney: GetFormattedMoney) => {
  const pricingOptionId = getActivePricingOptionId(place)
  return getPricingOptionLabel(place.ticket, pricingOptionId, getFormattedMoney)
}

export const getWholeTablePricingOptionsLabels = (
  place: PlaceWithTicketInfo,
  t: TFunction,
  getFormattedMoney: GetFormattedMoney,
) => {
  const selectedPricingOptionIds = getSelectedPricingOptionIds(place)
  const pricingOptions = getPricingOptions(place.ticket)
  const lines = pricingOptions
    .map(option => {
      const count = selectedPricingOptionIds.filter(id => id === option.id).length
      if (count) {
        const nameAndPrice = getPricingOptionLabel(place.ticket, option.id, getFormattedMoney)
        if (count === 1) {
          return t('seating_tableReservation_pricingOptionSelection_singular', {nameAndPrice})
        } else {
          return t('seating_tableReservation_pricingOptionSelection_plural', {nameAndPrice, seatsNumber: count})
        }
      }
      return undefined
    })
    .filter(Boolean)
  return lines
}

export const getWholeTableReservationPrice = ({
  event,
  place,
  t,
  getFormattedMoney,
  getTicketPriceText,
  noServiceFee,
}: {
  event: wix.events.Event
  place: PlaceWithTicketInfo
  t: TFunction
  getFormattedMoney: GetFormattedMoney
  getTicketPriceText: GetTicketPriceText
  noServiceFee?: boolean
}) => {
  const {ticket, places, donation, selectedPricingOptionIds} = place
  const isDonation = isDonationTicketDefinition(ticket)
  const isPricingOptions = hasPricingOptions(ticket)

  if (isDonation && !donation) {
    return ''
  }

  const getTotal = (priceOverrides: string[]) => {
    const ticketDefs = noServiceFee ? [{...ticket, wixFeeConfig: null}] : [ticket]
    return getPreliminaryInvoice(
      ticketDefs,
      {[ticket.id]: {quantity: places.length, priceOverrides}},
      getTaxConfig(event) as wix.events.TaxConfig,
    ).grandTotal
  }

  if (isPricingOptions && !selectedPricingOptionIds) {
    if (isPricingOptionsWithSamePrice(ticket)) {
      const price = getPricingOptions(ticket)[0].price
      const total = getTotal(new Array(places?.length).fill(price.amount))
      return getTicketPriceText(total)
    } else {
      const pricingRange = getPricingOptionsRange(ticket)
      const minTotal = getTotal(new Array(places?.length).fill(pricingRange.min.amount))
      const maxTotal = getTotal(new Array(places?.length).fill(pricingRange.max.amount))
      return t('seating_tableReservation_range', {min: getFormattedMoney(minTotal), max: getFormattedMoney(maxTotal)})
    }
  }

  let priceOverrides: string[]
  if (isDonation) {
    priceOverrides = new Array(places?.length ?? 0).fill(donation ?? '0')
  } else if (isPricingOptions) {
    priceOverrides = selectedPricingOptionIds?.map(
      pricingOptionId => getPricingOption(ticket, pricingOptionId).price.amount,
    )
  }

  return getTicketPriceText(getTotal(priceOverrides))
}

export const getSelectedPricingOptionIds = (place: PlaceWithTicketInfo) => place.selectedPricingOptionIds
export const hasSelectedPricingOptions = (place: PlaceWithTicketInfo) =>
  Boolean(getSelectedPricingOptionIds(place)?.length)

export const getStrictPriceOptionId = (place: PlaceWithTicketInfo) => place.pricingOptionId
export const hasStrictPricingOption = (place: PlaceWithTicketInfo) => Boolean(getStrictPriceOptionId(place))

export const getActivePricingOptionId = (place: PlaceWithTicketInfo) =>
  getStrictPriceOptionId(place) ?? getSelectedPricingOptionIds(place)?.[0]

export const getPlaceInfoItems = (place: PlaceWithTicketInfo, t: TFunction): {label: string; value: string}[] => {
  const {section, elementType, elementLabel, label, places} = place

  const placeInfo = [
    {label: t('seatings_sector'), value: section},
    {label: getElementLabelTitle(elementType, t), value: elementLabel},
  ]

  if (isPlaceReservableAsWhole(place)) {
    placeInfo.push({label: t('seating_seatQuantity'), value: String(places.length)})
  } else if (!isPlaceArea(place)) {
    placeInfo.push({label: t('seatings_seat'), value: label})
  }

  return placeInfo
}

export const getTotalPlaceQuantity = (places: PlaceWithTicketInfo[], planPlaceId: string): number =>
  places
    .filter(({planPlaceId: filteredPlanPlaceId}) => filteredPlanPlaceId === planPlaceId)
    .reduce((sum, {quantity}) => sum + quantity, 0)

export const getPlaceStock = (places: PlaceWithTicketInfo[], place: PlaceWithTicketInfo) => {
  const {ticket} = place

  if (!ticket?.id || saleEnded(ticket)) {
    return 0
  }

  if (isPlaceReservableAsWhole(place) && place.places.some(item => !item.capacity)) {
    return 0
  }

  const stock = place.capacity - getTotalPlaceQuantity(places, place.planPlaceId)
  return stock < 0 ? 0 : stock
}

export const getSelectedPlaceQuantity = (state: State, ticketId: string, placeId: string) =>
  getPlacesInBasket(state).find(place => place.ticket?.id === ticketId && place.id === placeId)?.quantity ?? 0

interface GetPlaceWithTicketInfoParams {
  place: Place
  category: Category
  sections: Section[]
  tickets: wix.events.ticketing.TicketDefinition[]
  seatingReservationSummary: SeatingReservationsSummary
}

export const getPlaceWithTicketInfo = ({
  place: {id, label, capacity},
  category,
  sections,
  tickets,
  seatingReservationSummary,
}: GetPlaceWithTicketInfoParams): PlaceWithTicketInfo => {
  const {
    externalId,
    config: {color},
  } = category ?? {config: {}}
  const ticketDefinition = getTicketDefinitionByExternalId(tickets, externalId)

  const elementId = getElementIdByPlaceId(id)
  const sectionId = getSectionIdByPlaceId(id)
  const section = sections.find(item => item.id === sectionId)
  const element = section.elements.find(item => item.id === elementId)

  return {
    id,
    planPlaceId: id,
    elementType: element.type,
    label,
    color,
    capacity: capacity - seatingReservationSummary.places.find(({placeId}) => placeId === id).occupied,
    quantity: 0,
    sectionId,
    section: sectionId === 0 ? undefined : section.title,
    elementLabel: element.title,
    elementId: element.id,
    ticket: ticketDefinition,
    donation: undefined,
    reservationOptions: element?.reservationOptions,
  }
}

export const pricingOptionPlaceId = (id: string, pricingOptionId: string) => `${id}_${pricingOptionId}`

export const getElementIdByPlaceId = (placeId: string): number => Number(placeId.split('-')[1])

export const getSectionIdByPlaceId = (placeId: string): number => Number(placeId.split('-')[0])
