import {SocialNetworks, handleKeyPressEnterOrSpace} from '@wix/panda-js-utils'
import {SHARE_BUTTON_NAMES} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {getShareUrls} from '../../../../../commons/services/share'
import {Facebook as FacebookIcon} from '../../../../../icons/Facebook'
import {Linkedin as LinkedinIcon} from '../../../../../icons/Linkedin'
import {X as XIcon} from '../../../../../icons/X'
import cl from '../classnames.scss'
import s from './social-share.scss'
import {SocialShareProps} from './index'

const {Facebook, LinkedIn, Twitter: X} = SocialNetworks

export class SocialShare extends React.Component<SocialShareProps> {
  shareUrls = getShareUrls(this.props.eventUrl, this.props.t)

  shareOnFacebook = () => {
    this.props.shareEvent(SHARE_BUTTON_NAMES.FACEBOOK, this.props.page)
  }

  shareOnX = () => {
    this.props.shareEvent(SHARE_BUTTON_NAMES.X, this.props.page)
  }

  shareOnLinkedin = () => {
    this.props.shareEvent(SHARE_BUTTON_NAMES.LINKEDIN, this.props.page)
  }

  render() {
    const {t} = this.props

    const iconsMeasurements = {
      [Facebook]: {width: '19', height: '16'},
      [X]: {width: '19', height: '16'},
      [LinkedIn]: {width: '19', height: '16'},
    }

    return (
      <div className={classNames(s.shareIcons, cl.socialShare)} data-hook="social-share">
        <a
          target="_blank"
          href={this.shareUrls[Facebook]}
          className={s.shareIcon}
          data-hook="social-share-icon-facebook"
          tabIndex={0}
          aria-label={t('a11y.shareButtonLabel', {
            button: 'Facebook',
          })}
          onClick={this.shareOnFacebook}
          onKeyPress={handleKeyPressEnterOrSpace(this.shareOnFacebook)}
          rel="noreferrer"
        >
          <FacebookIcon {...iconsMeasurements[Facebook]} />
        </a>
        <a
          target="_blank"
          href={this.shareUrls[X]}
          className={s.shareIcon}
          style={{margin: `0 ${this.props.margin || 30}px`}}
          data-hook="social-share-icon-twitter"
          tabIndex={0}
          aria-label={t('a11y.shareButtonLabel', {
            button: 'X',
          })}
          onClick={this.shareOnX}
          onKeyPress={handleKeyPressEnterOrSpace(this.shareOnX)}
          rel="noreferrer"
        >
          <XIcon {...iconsMeasurements[X]} />
        </a>
        <a
          target="_blank"
          href={this.shareUrls[LinkedIn]}
          className={s.shareIcon}
          data-hook="social-share-icon-linkedin"
          tabIndex={0}
          aria-label={t('a11y.shareButtonLabel', {
            button: 'LinkedIn',
          })}
          onClick={this.shareOnLinkedin}
          onKeyPress={handleKeyPressEnterOrSpace(this.shareOnLinkedin)}
          rel="noreferrer"
        >
          <LinkedinIcon {...iconsMeasurements[LinkedIn]} />
        </a>
      </div>
    )
  }
}
