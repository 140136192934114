import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../../commons/hooks/use-mobile-font-overrides'
import {useSettings} from '../../../../../hooks/settings'
import {settingsParams} from '../../../../../settingsParams'
import cl from '../../../classnames.scss'
import {ReadMoreButtonProps} from './interfaces'
import s from './read-more-button.scss'

export const ReadMoreButton = ({visible, collapsed, onClick}: ReadMoreButtonProps) => {
  const {isMobile} = useEnvironment()
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const settings = useSettings()
  const readMoreText = settings.get(settingsParams.readMoreText)
  const readLessText = settings.get(settingsParams.readLessText)

  return (
    <button
      data-hook={DH.ABOUT_SECTION_BUTTON}
      className={classNames(s.button, cl.evTextFont, cl.linksColor, {
        [s.mobile]: isMobile && mobileFontOverridesEnabled,
        [s.hidden]: !visible,
      })}
      onClick={onClick}
    >
      {collapsed ? readMoreText : readLessText}
    </button>
  )
}
