import loadable from '@wix/yoshi-flow-editor/loadable'
import React from 'react'

export const FormEntry = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "form" */ '../router/form-entry'),
)

export const StatusScreensEntry = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "status-screens" */ '../router/status-screens-entry'),
)

export const SeatingPlanModal = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "seating-plan-modal" */ '../modals/seating-plan'),
)

export const OrderSummary = React.lazy(
  () => import(/* webpackChunkName: "order-summary-modal" */ '../modals/order-summary'),
)

export const TicketsDownload = React.lazy(
  () => import(/* webpackChunkName: "tickets-download-modal" */ '../modals/tickets-download'),
)

export const ConfirmRsvp = React.lazy(
  () => import(/* webpackChunkName: "confirm-rsvp-modal" */ '../modals/confirm-rsvp'),
)

export const HeadlessError = React.lazy(
  () => import(/* webpackChunkName: "headless-error-modal" */ '../modals/headless-error'),
)

export const RicosViewer = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "ricos-viewer" */ '../event-details/ricos-viewer'),
)

export const MembersOnlyError = React.lazy(
  () => import(/* webpackChunkName: "members-only-error" */ '../modals/members-only-error'),
)
